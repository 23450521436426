<template>
  <div style="color: #000;height: 100%;">
    <van-nav-bar :title="cname+'储值卡流水'" left-arrow :fixed="true" :z-index="999" @click-left="back">
      <template #right></template>
    </van-nav-bar>
    <div class="h1_c">
      <div class="left">
        <van-icon name="user-circle-o" style="vertical-align: middle" />
        <span style="vertical-align: middle">{{userInfo?userInfo.realName:''}}</span>
      </div>
      <div class="right">
        <van-icon name="phone-circle-o" style="vertical-align: middle" />
        <span style="vertical-align: middle">{{userInfo?userInfo.phone:''}}</span>
      </div>
    </div>
    <div style="padding-top:91px; text-align: center;">
      <div class="lis" v-for="(item,idx) in list" :key="idx" @click="toxiangqin(item)">
        <div class="name_s">
          <div class="left">
            <span v-if="item.bizType== 1">充值</span>
            <span v-else>
              <span v-if="item.orderType== 1">{{item.carNo}} 维修</span>
              <span v-if="item.orderType== 2">线上消费</span>
              <span v-if="item.orderType== 3">消费合伙人办理</span>
              <span v-if="item.orderType== 4">在线充值</span>
              <span v-if="item.orderType== 5">在线充值</span>
            </span>
          </div>
          <div class="right">
            <span v-if="item.bizType== 1" style="color:red">
              +{{item.balance+item.giftBalance}}
              <span v-if="item.giftBalance > 0">(含赠送:￥{{item.giftBalance}})</span>
            </span>
            <span v-else style="color:#32CD32">-{{item.balance+item.giftBalance}}</span>
          </div>
        </div>
        <div class="name_s" style="color:#666;font-size: 12px;">
          <div class="left" v-if="item.orderType== 1">{{item.garageName}}</div>
          <div class="right">{{item.createdDate}}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import orderModel from "../../api/order";
import utils from "../../assets/utils";
// import preventBack from "vue-prevent-browser-back"; //组件内单独引入
export default {
  components: {},
  // mixins: [preventBack], //注入
  data() {
    return {
      chexin: JSON.parse(sessionStorage.getItem("chexin")),
      shangjia: JSON.parse(sessionStorage.getItem("shangjia")),
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      cname:'',
      gid: "",
      customerId: "",
      isok: "false",
      xsiosk: false,
      list: [],
      page: 0,
      size: 10,
      loading: false,
      total: 0,
      finished: false
    };
  },
  methods: {
    toxiangqin(item) {
      this.$router.push({
        path: "consume_details",
        query: {
          id: 0,
          title: "消费记录详情",
          billNumber:item.billNumber
        }
      });
    },
    onLoad() {
      // const loading = this.$toast.loading({
      //   mask: true,
      //   duration: 0,
      //   message: "加载中..."
      // });
      //xsiosk== true 就是线上储值消费记录，反之就是本地储值金消费记录
      if (this.xsiosk == "true") {
        const data = {
          phone: this.userInfo.phone,
        };
        orderModel
          .onlinegetMemberStoreRecord(data)
          .then(e => {
            // loading.clear();
            this.list = e.data;
          })
          .catch(() => loading.clear());
      } else {
        if (this.isok == "true") {
          console.log('走了这')
          const data = {
            customerId: this.customerId,
            // customerId: 7,
            gid: this.gid
          };
          orderModel
            .getMemberStoreRecord(data)
            .then(e => {
              // loading.clear();
              this.list = e.data;
            })
            .catch(() => loading.clear());
        } else {
          const data = {
            customerId: this.userInfo.customerId,
            // customerId: 7,
            gid: this.shangjia.id
          };
          orderModel
            .getMemberStoreRecord(data)
            .then(e => {
              // loading.clear();
              this.list = e.data;
            })
            .catch(() => loading.clear());
        }
      }
    },
    onLoads() {
      //   this.page++;
      this.onLoad();
    },
    // pushHistory() {
    //   //写入空白历史路径
    //   let state = {
    //     title: "title",
    //     url: "#"
    //   };
    //   window.history.pushState(state, state.title, state.url);
    // },

    // backziding() {
    //   this.pushHistory();
    //   window.addEventListener(
    //     "popstate",
    //     function(e) {
    //       location.href = "/user";
    //     },
    //     false
    //   );
    // },
    back() {
      this.$router.go(-1);
      // this.$router.push({
      //   path: "user"
      // });f
    }
  },
  created() {
    this.isok = utils.getUrlKey("isok");
    this.gid = utils.getUrlKey("gid");
    this.customerId = utils.getUrlKey("customerId");
    this.xsiosk = utils.getUrlKey("xsiosk");
    if(utils.getUrlKey("cname") == '线上'){
      this.cname ='线上'
    }else{
      this.cname ='本地'
    }
    this.onLoad();
  },
  mounted() {
    // this.backziding();
  }
};
</script>

<style lang="less" scoped>
.h1_c {
  overflow: hidden;
  position: fixed;
  padding: 0 12px;
  top: 46px;
  font-size: 12px;
  width: 100%;
  height: 45px;
  line-height: 41px;
  border-bottom: 4px solid #f5f5f5;
  color: #666;
  background: #fff;
  z-index: 9;
}
.lis {
  margin: 0 12px;
  overflow: hidden;
  font-size: 13px;
  border-bottom: 1px solid #f5f5f5;
  // padding-bottom: 8px
  padding: 8px 0;
}
.name_s {
  overflow: hidden;
  line-height: 30px;
}
</style>